<template>
    <v-row align="center" justify="center">
      <v-col
        cols="12"
        sm="8"
        md="5"
        class="text-center px-5"
      >
        <v-progress-circular indeterminate size="90" color="grey" />
        <h3 class="font-weight-medium mt-10 text--secondary">
          Attendendere, caricamento sessione in corso...</h3>
      </v-col>
    </v-row>
</template>

<script>
export default {
  name: 'LoadingPlaceHolder',
}
</script>

<style scoped></style>
